'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FooterMain = /** @class */function () {
    function FooterMain() {
        this.footerDom = document.getElementById('footer');
        this.init();
    }
    FooterMain.prototype.init = function () {
        this.footerEvent();
    };
    FooterMain.prototype.footerEvent = function () {
        var that = this;
        // footer客服-点击
        var kefuBtn = _tool2.default.$_select(that.footerDom, '.kefu');
        kefuBtn.addEventListener('click', function () {
            window.open(ysf('url'), '_blank');
        });
        // footer分类打开关闭-点击
        var footerCategoryListItem = _tool2.default.$_selectAll(that.footerDom, '.footer-category-list-item');
        var footerCategoryListItemTitle = _tool2.default.$_selectAll(that.footerDom, '.footer-category-list-item-title');
        footerCategoryListItemTitle.forEach(function (item) {
            item.addEventListener('click', function () {
                var hasActive = item.parentNode.classList.contains('active');
                if (hasActive) {
                    item.parentNode.classList.remove('active');
                } else {
                    footerCategoryListItem.forEach(function (fItem) {
                        return fItem.classList.remove('active');
                    });
                    item.parentNode.classList.add('active');
                }
            });
        });
    };
    return FooterMain;
}();
exports.default = FooterMain;