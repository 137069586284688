'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HeaderMain = /** @class */function () {
    function HeaderMain() {
        this.headerDom = document.getElementById('header');
        this.init();
    }
    HeaderMain.prototype.init = function () {
        this.headerEvent();
    };
    HeaderMain.prototype.headerEvent = function () {
        var that = this;
        // header菜单打开和关闭-点击
        var headerNavIcon = _tool2.default.$_select(that.headerDom, '.kingshiper-header-nav-icon');
        var headerSearchIcon = _tool2.default.$_select(that.headerDom, '.kingshiper-header-search-icon');
        var headerCategoryList = _tool2.default.$_select(that.headerDom, '.kingshiper-header-category-list');
        var headerCloseIcon = _tool2.default.$_select(that.headerDom, '.kingshiper-header-close-icon');
        headerCloseIcon.addEventListener('click', function () {
            _tool2.default.toggleShowAndHide({
                hideTarget: [headerCloseIcon, headerCategoryList],
                showTarget: [headerNavIcon, headerSearchIcon]
            });
        });
        headerNavIcon.addEventListener('click', function () {
            _tool2.default.toggleShowAndHide({
                hideTarget: [headerNavIcon, headerSearchIcon],
                showTarget: [headerCloseIcon, headerCategoryList]
            });
        });
        // header产品分类打开和关闭-点击-一级
        var headerCategoryListLiveItem = _tool2.default.$_selectAll(that.headerDom, '.kingshiper-header-category-list-content-box-live1-item');
        var headerCategoryListLiveItemTitle = _tool2.default.$_selectAll(that.headerDom, '.kingshiper-header-category-list-content-box-live1-item-title');
        headerCategoryListLiveItemTitle.forEach(function (item) {
            item.addEventListener('click', function () {
                // 切换时不展开其他分类
                var hasActive = item.parentNode.classList.contains('live1-item');
                if (hasActive) {
                    item.parentNode.classList.remove('live1-item');
                } else {
                    headerCategoryListLiveItem.forEach(function (hItem) {
                        return hItem.classList.remove('live1-item');
                    });
                    item.parentNode.classList.add('live1-item');
                }
            });
        });
        // header产品分类打开和关闭-点击-二级
        var headerCategoryListItem = _tool2.default.$_selectAll(that.headerDom, '.kingshiper-header-category-list-item');
        var headerCategoryListItemTitle = _tool2.default.$_selectAll(that.headerDom, '.kingshiper-header-category-list-item-title');
        headerCategoryListItemTitle.forEach(function (item) {
            item.addEventListener('click', function () {
                item.parentNode.classList.toggle('live2-item');
            });
        });
        // 全部产品-点击
        var allProductDom = _tool2.default.$_select(that.headerDom, '.kingshiper-header-category-bottom-btn');
        allProductDom.addEventListener('click', function () {
            var flag = false;
            var type = '';
            try {
                headerCategoryListItem.forEach(function (item) {
                    if (item.classList.contains('active')) {
                        flag = true;
                        type = item.getAttribute('data-type');
                        throw Error();
                    } else {
                        flag = false;
                    }
                });
            } catch (e) {
                // console.log('用于跳出foreach循环')
            }
            if (!flag) {
                window.location.href = '/product';
            } else {
                window.location.href = "/product/".concat(type);
            }
        });
    };
    return HeaderMain;
}();
exports.default = HeaderMain;